<template>
    <button type="submit" @click="googleAuth" class="main-outlined secondary mt-2 d-flex align-items-center">
        <img src="@/assets/googleColorLogo.svg" alt="google logo">
        <span>{{ t('loginGoogle') }}</span>
    </button>
</template>

<script>
import GAuth from 'vue-google-oauth2'
import Vue from 'vue'
import cookies from 'vue-cookies'

export default {
    mounted() {
        const gAuthOption = {
            clientId: "827439262602-acrsj7a603qgg5k7ih055a6flcap97b6.apps.googleusercontent.com",
            clientSecret: "Qs-Gz7bMDVatuC5tvqRslPHK",
            prompt: 'select_account'
        };
        Vue.use(GAuth, gAuthOption);
    },
    methods: {
        async googleAuth() {
            event?.preventDefault()
            const providerResponse = await this.$gAuth.signIn();
            const authResponse = providerResponse.getAuthResponse();
            const resp = await this.axios.post('auth/google/callback', {access_token: authResponse.access_token})
            const data = resp.data.data
            const token = data.token_type + ' ' + data.access_token
            cookies.set('token', token, 364 * 24 * 3600)
            this.axios.defaults.headers.common['Authorization'] = token;
            this.$store.commit('loggedWithCookies')
            await this.$store.dispatch('receiveUserInfo').then(() => {
                this.$router.push('/')
            })
        },
    }
}
</script>

<style scoped>
.secondary{
    font-size: 17px;
    display: flex;
    justify-content:center;
    align-self: center;
    width: 250px;
}
.secondary img {
    margin-right: 10px;
}

</style>