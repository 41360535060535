<template>
  <form class="login-frame" @submit.prevent="submit">
    <h2 class="fw-bold mb-4">{{ t("login") }}:</h2>
    <input
      type="email"
      class="mb-3 input"
      :placeholder="t(`yourEmail`)"
      v-model="email"
    />
    <input
      type="password"
      class="mb-3 input"
      :placeholder="t(`yourPasword`)"
      v-model="password"
    />
    <div class="d-flex align-items-center checkbox-row mt-2">
      <div class="round">
        <input type="checkbox" id="checkbox" v-model="remember" />
        <label for="checkbox"></label>
      </div>
      <label class="remember" for="checkbox">{{ t("rememberMe") }}</label>
    </div>
    <vue-recaptcha
      sitekey="6LcHiQwdAAAAACdTEEeEYA0kG77VmqwYH36O6hBw"
      :loadRecaptchaScript="true"
      class="captcha mt-2"
      :language="currLang"
      @verify="verify"
    />
    <button type="submit" class="main mt-3">{{ t("login") }}</button>
    <google-button />
    <b-button variant="link" v-b-modal.modal-center class="mt-3">{{
      t("dontRememberPassword")
    }}</b-button>
    <!-- <p class="text-muted mt-5 mb-2">{{ t('noProfile') }}</p> -->
    <router-link to="/registrate" class="mt-5">{{
      t("registrate")
    }}</router-link>
    <b-modal id="modal-center" centered :title="t(`yourEmail`)" hide-footer>
      <b-form-input
        v-model="email"
        type="email"
        :placeholder="t(`yourEmail`)"
      />
      <div class="w-100 d-flex justify-content-end mt-3">
        <button class="main" @click="drop()">{{ t("send") }}</button>
      </div>
    </b-modal>
  </form>
</template>

<script>
import GoogleButton from "../components/GoogleButton.vue";
import vueRecaptcha from "vue-recaptcha";

export default {
  components: { GoogleButton, vueRecaptcha },
  name: "Login",
  metaInfo() {
    return {
      title: "Techvibe " + this.t("login"),
      meta: [
        { name: "description", content: "Techvibe " + this.t("login") },
        { property: "og:title", content: "Techvibe " + this.t("login") },
        { property: "og:site_name", content: "Techvibe" },
        { property: "og:type", content: "website" },
      ],
      htmlAttrs: {
        lang: this.currLang,
      },
    };
  },
  data() {
    return {
      email: "",
      password: "",
      remember: true,
      notRobot: false,
    };
  },
  methods: {
    verify() {
      this.notRobot = true;
    },
    async submit() {
      event?.preventDefault();
      if (!this.notRobot) {
        this.error(this.t("verifyNotRobot"));
        return;
      }
      if (!this.email || !this.password) {
        this.error(this.t("fillEmailField"));
        return;
      } else if (!this.email.includes("@") || !this.email.includes(".")) {
        this.error(this.t("wrongEmail"));
        return;
      }
      try {
        await this.$store.dispatch("loginWithEmail", {
          email: this.email,
          password: this.password,
          remember: this.remember,
        });
        this.$router.push("/");
      } catch (e) {
        if (e.response?.data?.message == "Credentials mismatch") {
          this.error(this.t("emailOrPasswordFilledWrong"));
        }
      }
    },
    async drop() {
      if (
        !this.email ||
        !this.email.includes("@") ||
        !this.email.includes(".")
      ) {
        this.error(this.t("wrongEmail"));
        return;
      }
      this.axios.post("auth/email/reset", { email: this.email });
      this.$bvModal.hide("modal-center");
      this.alert(this.t("newPasswordHasSent"));
    },
  },
};
</script>

<style scoped lang="scss">
.login-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 0 auto;
  margin-top: 50px;
  width: 790px;
  height: 648px;
  box-shadow: 0px -40px 32px -24px rgba(0, 0, 0, 0.12),
    0px 40px 32px -24px rgba(15, 15, 15, 0.12);
  backdrop-filter: blur(32px);
  background-image: url("../assets/loginbg.png");
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  margin-bottom: 100px;

  .captcha {
    transform: scale(0.83);
    transform-origin: 0% 0;
    margin-left: 52px;
    margin-top: 10px;
    margin-bottom: -10px;
  }
  .input {
    width: 250px;
    height: 40px;
  }
  button.main,
  button.secondary {
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-self: center;
    width: 250px;
  }
  button.secondary {
    img {
      margin-right: 10px;
    }
  }
  .checkbox-row {
    width: 250px;
    label {
      cursor: pointer;
    }
    .remember {
      margin-left: 20px;
      margin-bottom: 5px !important;
    }
    .round {
      position: relative;
    }
    .round label {
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 50%;
      cursor: pointer;
      height: 20px;
      left: 0;
      position: absolute;
      top: 0;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .round label:after {
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      content: "";
      height: 4px;
      opacity: 0;
      position: absolute;
      transform: rotate(-45deg);
      width: 8px;
    }
    .round input[type="checkbox"] {
      visibility: hidden;
    }
    .round input[type="checkbox"]:checked + label {
      background-color: #66bb6a;
      border-color: #66bb6a;
    }
    .round input[type="checkbox"]:checked + label:after {
      opacity: 1;
    }
  }
}
button.btn-link {
  color: var(--itpark);
}
@media (max-width: 800px) {
  .login-frame {
    border-radius: 0 !important;
    margin-top: 3px !important;
    width: 100% !important;
    height: 600px !important;
    box-shadow: none !important;
  }
}
</style>